import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SvgUse from '@/components/graphics/SvgUse';

import ThemeContext from '@/context/Theme';

export const StyledCompanyLogo = styled.div`
  display: inline-block;
  overflow: hidden;
  align-content: center;

  > img,
  > svg {
    margin: auto;
    padding: 0;
  }
`;
export const StyledCompanyFullLogo = styled(StyledCompanyLogo)``;
export const StyledCompanyLogoMiniWhite = styled(StyledCompanyLogo)``;
export const StyledCompanySimplifiedImage = styled(StyledCompanyLogo)``;
export const StyledCompanyFullImage = styled(StyledCompanyLogo)``;

const CompanyLogo = ({ simplified = false, white = false, ...rest }) => {
  // Then figure out if we should change the logo based on available Teams
  // customization options
  const { customized, companyLogo, simplifiedLogo } =
    React.useContext(ThemeContext);

  // Set default Deskpass logo, either full-size
  // or smaller flavor without text
  let logo = simplified ? (
    <StyledCompanyLogo>
      <SvgUse
        id={`deskpass-logo-mini${white ? '-white' : ''}`}
        category="full"
        width="11"
        height="12"
        {...rest}
      />
    </StyledCompanyLogo>
  ) : (
    <StyledCompanyFullLogo>
      <SvgUse
        id="deskpass-logo"
        category="full"
        width="118"
        height="33"
        {...rest}
      />
    </StyledCompanyFullLogo>
  );

  if (customized) {
    if (companyLogo || simplifiedLogo) {
      // Use custom logo if set
      logo =
        (simplified || !companyLogo) && simplifiedLogo ? (
          <StyledCompanySimplifiedImage>
            <img src={simplifiedLogo} alt="Company Logo" {...rest} />
          </StyledCompanySimplifiedImage>
        ) : (
          <StyledCompanyFullImage>
            <img src={companyLogo} alt="Company Logo" {...rest} />
          </StyledCompanyFullImage>
        );
    }
  }

  return logo;
};

CompanyLogo.propTypes = {
  simplified: PropTypes.bool,
  white: PropTypes.bool,
};

export default CompanyLogo;
