import Menu from '@/components/menu';
import NotificationsCentral from '@/components/notifications/NotificationsCentral';

export default function TopContentLayout() {
  return (
    <>
      <NotificationsCentral />
      <Menu />
    </>
  );
}
