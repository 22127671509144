import styled from 'styled-components';

import { rgba } from '@/styles/js/utils/colors';
import { scaleWithVW } from '@/styles/js/utils/scaling';

export const ScrollableArea = styled.section`
  position: relative;
  overflow: hidden;
`;

export const ScrollableAreaInner = styled.div`
  display: flex;
  gap: ${scaleWithVW('0.5rem')};

  & > * {
    flex-shrink: 0;
  }

  /* Hide scrollbar  */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const gradientWhiteTransition = [0.7, 0.9].map(it => rgba('white', it)).join(',');

export const ScrollableEdge = styled.div`
  width: 7.5%;

  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
  }

  &:first-child {
    padding-left: 4px;
    left: 0;
    justify-content: flex-start;
    background: linear-gradient(to left, transparent, ${gradientWhiteTransition}, white);
  }

  &:last-child {
    padding-right: 4px;
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(to right, transparent, ${gradientWhiteTransition}, white);
  }
`;
