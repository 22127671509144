import PropTypes from 'prop-types';
import { useEffect } from 'react';

import Icon from '@/components/graphics/Icon';

import { childrenType } from '@/lib/props';
import { addHTMLClass, removeHTMLClass } from '@/lib/utility';

import * as Styled from './index.styles';

const FilterButton = ({
  selected = false,
  primary = false,
  reverse = false,
  active = false,
  children,
  icon,
  ...btnProps
}) => {
  // Add/remove class to HTML element when filter is open/closed so we can
  // modify behavior of things outside of React, like the Hubspot chat widget.
  useEffect(() => {
    if (selected) {
      addHTMLClass('filter-open');
      addHTMLClass('hide-chat-widget');
    } else {
      removeHTMLClass('filter-open');
      removeHTMLClass('hide-chat-widget');
    }
  }, [selected]);

  return (
    <Styled.Button
      {...btnProps}
      selected={selected}
      primary={primary}
      reverse={reverse}
      active={active}
    >
      {!!icon && <Icon type={icon} />}
      <Styled.Text>{children}</Styled.Text>
    </Styled.Button>
  );
};

FilterButton.propTypes = {
  children: childrenType,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  primary: PropTypes.bool,
  reverse: PropTypes.bool,
  active: PropTypes.bool,
};

export default FilterButton;
