import { useRouter } from 'next/navigation';
import { memo, useCallback, useEffect, useState } from 'react';

import Notification from '@/components/notifications/Notification';
import NotificationTransitionGroup from '@/components/notifications/NotificationTransitionGroup';
import AccountOnHold from '@/components/notifications/custom/AccountOnHoldNotification';
import CreditCardExpiredNotification from '@/components/notifications/custom/CreditCardExpiredNotification';
import MaintenanceNotification from '@/components/notifications/custom/Maintenance';
import SignupCTA from '@/components/notifications/custom/SignupCTANotification';
import TeamAnnouncementNotification from '@/components/notifications/custom/TeamAnnouncement';

import { useNotificationContext } from '@/context/Notification';

import useEvent from '@/hooks/useEvent';

import {
  getClosedNotifications,
  markNotificationAsClosed,
} from '@/lib/notificationHelpers';

import IncomingBreatherMemberNotification from '../breather/IncomingBreatherMemberNotification';

/*
 *
 * The order here define the priority of the notifications from top to bottom
 * (most important on top).
 *
 * Create any new persistent notification under "@/components/notifications/custom/"
 * and then drop it here in this array on the priority you feel like it should have.
 *
 * We're using array of tuples here to use the first index as name and second as the actual component
 * Name should be unique and can be the same name as the component (That should be unique in this case).
 *
 */
const Notifications = [
  ['MaintenanceNotification', MaintenanceNotification],
  ['IncomingBreatherMemberNotification', IncomingBreatherMemberNotification],
  ['SignupCTA', SignupCTA],
  ['CreditCardExpired', CreditCardExpiredNotification],
  ['AccountOnHold', AccountOnHold],
  ['TeamAnnouncement', TeamAnnouncementNotification],
];

const initializeNotificationState = (list) =>
  list.reduce(
    (notificationOpened, [name]) => ({
      ...notificationOpened,
      [name]: true,
    }),
    {},
  );

/*
 *
 * This component shouldn't care about the specific rules of a given Persistent Notification
 * It only always show the first most important.
 * Any specific logic go inside the notification itself
 *
 */
const PersistentNotificationList = memo(() => {
  const [notificationOpened, setNotificationOpened] = useState(
    initializeNotificationState(Notifications),
  );
  const [locallyClosedNotifications, setLocallyClosedNotifications] = useState(
    {},
  );
  const router = useRouter();
  const { persistentNotifications, destroyPersistent, notifications } =
    useNotificationContext();

  const isFromContext = useEvent((name) =>
    persistentNotifications.some(({ id }) => id === name),
  );

  useEffect(() => {
    const fetchClosedNotifications = async () => {
      const locallyClosedNotifications = await getClosedNotifications();
      setLocallyClosedNotifications(locallyClosedNotifications);
    };

    fetchClosedNotifications();
  }, []);

  const closeNotification = useEvent((notificationName) => (e) => {
    e.stopPropagation();

    if (isFromContext(notificationName)) {
      return destroyPersistent(notificationName);
    }

    markNotificationAsClosed(notificationName);
    setNotificationOpened((prev) => ({ ...prev, [notificationName]: false }));
  });

  const getNotificationsFromContext = useCallback(
    () =>
      persistentNotifications.map((it) => [
        it.id,
        () => (
          <Notification
            special={it.style === 'special'}
            warning={it.style === 'warning'}
            timeout={it.timeout}
            icon={it.url ? 'arrow' : 'close'}
            onClick={() => {
              if (it.url) router.push(it.url);
            }}
            onIconClick={(e) => {
              if (!it.url) return closeNotification(it.id)(e);

              router.push(it.url);
            }}
          >
            {it.message}
          </Notification>
        ),
      ]),
    [persistentNotifications, router, closeNotification],
  );

  const getNotifications = useCallback(
    () => getNotificationsFromContext().concat(Notifications),
    [getNotificationsFromContext],
  );

  const getNotificationsOpened = useCallback(
    () => ({
      ...initializeNotificationState(getNotifications()),
      ...notificationOpened,
      ...locallyClosedNotifications,
    }),
    [getNotifications, notificationOpened, locallyClosedNotifications],
  );

  const renderNotification = useCallback(
    (PersistentNotification, name) => {
      if (isFromContext(name) || getNotificationsOpened()[name]) {
        return (
          <PersistentNotification closeNotification={closeNotification(name)} />
        );
      }

      return null;
    },
    [getNotificationsOpened, isFromContext, closeNotification],
  );

  if (notifications.length) return null;

  return (
    <NotificationTransitionGroup
      persistent
      notificationsByKey={getNotifications()}
    >
      {renderNotification}
    </NotificationTransitionGroup>
  );
});

export default PersistentNotificationList;
