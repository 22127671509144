import styled, { css } from 'styled-components';

import typeStyle from '@/styles/js/fonts/typeStyles';

import { resetButtonStyles } from '@/styles/js/utils/interations';
import { notTouch } from '@/styles/js/utils/media';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';
import { rgba } from '@/styles/js/utils/colors';
import mq from '@/styles/js/utils/mq';
import { em } from '@/styles/js/utils/units';

import { Icon } from '@/components/graphics/Icon.styles';
import { CommonLink } from '@/components/buttons-links/CommonLink';

/**
 * TextLink Size
 * @param {string} size - `small | mini | large`
 */
const getTextLinkSize = ({ size }) => ({
  'small': css`
    ${typeStyle('body-s')}
  `,
  'mini': css`
    ${typeStyle('button-s')}

    font-size: 1.2rem;
  `,
  'large': css`
    font-size: 1.6rem;
  `,
}[size]);

/**
 * TextLink Color
 * @param {string} color - `warning | primary | reverse | space-subnav-small`
 */
const getTextLinkColor = ({ color, theme }) => ({
  'warning': css`
    color: ${theme.colors.red};
  `,
  'primary': css`
    &&&, 
    & span {
      color: ${theme.colors.primary};
    }

    &:hover > * {
      color: ${theme.colors.primaryTint};
    }
  `,
  'reverse': css`
    color: white;

    ${notTouch} {
      &:hover {
        color: white;
        opacity: 0.7;
      }
    }
  `,
  'space-subnav-small': css`
    > ${Icon} {
      color: ${theme.colors.iconsLight};
      margin-top: 0.1rem;
    }

    > span {
      font-weight: bold;
    }
  `,
}[color]);

/**
 * Styled TextLink
 * ---
 * 
 * props
 * - `<CommonLink /> props`
 * - `size`
 *    - `small` - A smaller version
 *    - `mini` - An even smaller version
 *    - `large`
 * - `color`
 *    - `primary`
 *    - `warning` - Indicates a destructive or important action
 *    - `reverse` - For use on dark backgrounds
 *    - `space-subnav-small` - Exclusive styling for SpaceSubnavSmall
 * - `arrow` - Includes an arrow icon
 * - `bold` - bool
 * - `underlined` - bool
 * - `nowrap` - bool
 */
export const TextLink = styled(CommonLink).attrs(({ className }) => ({
  className: `TextLink ${className ?? ''}`
}))`
  ${resetButtonStyles}
  ${typeStyle('body')}

  color: ${({ theme }) => theme.colors.accent};
  position: relative;

  ${notTouch} {
    &:hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  > ${Icon}:first-child {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.4rem;
  }

  > ${Icon}:first-child + span {
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.5rem;
  }

  &:focus {
    outline: none;

    ${mq('large')} {
      &::before {
        ${pseudoAbsolute}

        top: -0.4rem;
        right: -0.7rem;
        bottom: -0.4rem;
        left: -0.7rem;
        box-shadow: 0 1px 0.5rem ${rgba('black', 0.15)};
        border-radius: 0.4rem;
      }
    }
  }

  // ----------
  // Modifiers
  // ----------

  ${getTextLinkSize}
  ${getTextLinkColor}


  ${({ theme, underlined, nowrap, bold, arrow }) => css`
  
    ${underlined && css`
      > * {
        text-decoration: underline;
      }
    `}

    ${nowrap && css`
      & span {
        white-space: nowrap;
      }
    `}

    ${bold && css`
      & > * {
        font-weight: bold !important;
      }
    `}

    ${arrow && css`
      padding-right: 2.3rem;

      > ${Icon}:last-child {
        color: ${theme.colors.iconsLight};
        position: absolute;
        top: ${em(2, 15)};
        bottom: 0;
        margin: auto 0;
        right: 0;
      }
    
      &:hover > ${Icon}:last-child {
        color: ${theme.colors.text};
      }
    `}
  `}
`;