import { usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

import useEvent from '@/hooks/useEvent';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext();

const { Consumer, Provider } = Context;

/**
 * MoreModalProvider
 * ---
 * - Manage modal open and close state.
 * - Auto-detects and close modal when an internal link is clicked.
 *
 * __Context__
 * - `openMore` - {bool} modal state
 * - `toggleModal` - {func} toggles modal visibility
 *
 */
export function MoreModalProvider({ children }) {
  const [openMore, setOpen] = useState(false);
  const pathname = usePathname();

  const toggleMoreModal = useEvent(() => setOpen((prev) => !prev));

  // listen and close modal on pathname change
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const context = { openMore, toggleMoreModal };

  return <Provider value={context}>{children}</Provider>;
}

export const MoreModalConsumer = Consumer;
/**
 * withMoreModalContext
 * ---
 * Provides consumer with MoreModal context
 *
 * __Context__
 * - `openMore` - {bool} modal state
 * - `toggleModal` - {func} toggles modal visibility
 *
 */
export const withMoreModalContext = consumerToHOC(Consumer, 'moreModalContext');
export const useMoreModalContext = () => useContext(Context);
export default Context;
