import { safeWindow as window } from '@/lib/browser';

class BrowserWebviewClient {
  nativeWrapper = null;

  platform = null;
  version = null;

  ios = {
    trackingStatus: 'notDetermined',
  };

  constructor(wrapperInjectedObject = window.NativeWrapper) {
    this.nativeWrapper = wrapperInjectedObject;

    this.platform = this.nativeWrapper?.getPlatform?.();
    this.version = this.nativeWrapper?.getVersion?.();
  }

  isWebview() {
    return !!this.nativeWrapper;
  }

  isIos() {
    return this.platform === 'iOS';
  }

  isAndroid() {
    return this.platform === 'Android';
  }
}

export default BrowserWebviewClient;
