import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import FormSelect from '@/components/forms/FormSelect';
import Icon from '@/components/graphics/Icon';
import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';

const StyledFormTimePicker = styled.div`
  display: flex;

  > * {
    flex: auto;
  }

  ${StyledIcon} {
    color: ${({ theme }) => theme.colors.accent};
    margin: 1rem;
  }
`;

/**
 * Allows for simple start/end time selection.
 */
const FormTimePicker = ({
  start: initialStart = '',
  end: initialEnd = '',
  defaultStart = '',
  defaultEnd = '',
  onChange = () => {},
  disabled = false,
}) => {
  const [options, setOptions] = useState([]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    let start = moment({ hour: 0 });
    const generatedOptions = [{ label: 'Select...', value: '' }].concat(
      [...Array(48).keys()].map(() => {
        const value = {
          label: start.format('h:mm a'),
          value: start.format('h:mma'),
        };

        start.add(30, 'minutes');

        return value;
      }),
    );

    setOptions(generatedOptions);
    setStart(initialStart);
    setEnd(initialEnd);
  }, [initialStart, initialEnd]);

  const updateTimes = (newStart, newEnd) => {
    const optionKeys = options.map((option) => option.value);

    if (
      newStart &&
      newEnd &&
      optionKeys.indexOf(newEnd) < optionKeys.indexOf(newStart) + 1
    ) {
      newEnd =
        options[
          Math.min(optionKeys.indexOf(newStart) + 1, optionKeys.length - 1)
        ].value;
    }

    setStart(newStart);
    setEnd(newEnd);

    onChange({
      start: newStart,
      end: newEnd,
    });
  };

  return (
    <StyledFormTimePicker>
      <FormSelect
        narrow
        name="start"
        value={start || defaultStart}
        options={options}
        disabled={disabled}
        onChange={(value) => {
          updateTimes(value, end);
        }}
      />
      <Icon type="arrow-right-24" />
      <FormSelect
        narrow
        name="end"
        value={end || defaultEnd}
        options={options}
        disabled={disabled}
        onChange={(value) => {
          updateTimes(start, value);
        }}
      />
    </StyledFormTimePicker>
  );
};

FormTimePicker.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  defaultStart: PropTypes.string,
  defaultEnd: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(FormTimePicker);
