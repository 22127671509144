import { safeDocument as document, safeWindow as window } from '@/lib/browser';
import config from '@/lib/config';
import * as userAgent from '@/lib/userAgent';

// Initial settings
window.hsConversationsSettings = {
  loadImmediately: !userAgent.isMobile,
};

/**
 * We don't load the chat widget button on mobile to start because it gets in
 * the way of the interface, and the default open function the widget provides
 * won't load it for you if it isn't already loaded, so this just ensures it
 * actually opens no matter the status of the widget.
 */
export function open() {
  if (!window.HubSpotConversations.widget.status().loaded) {
    // If the widget isn't already loaded, load it first before attempting to
    // display it

    // widgetOpen here is supposed to load the widget with the message window
    // already open, but it kind of... doesn't do that, see thread here in the
    // future, hopefully they'll actually fix it:
    // https://community.hubspot.com/t5/APIs-Integrations/window-HubSpotConversations-widget-load-widgetOpen-true-doesn-t/td-p/288938
    window.HubSpotConversations.widget.load({ widgetOpen: true });

    // Until they do though, we just have to use this hacky bullshit instead
    // because they also don't provide a loaded callback or loaded event
    let interval = setInterval(() => {
      // You don't want to reference this.widget here because Hubspot is going
      // to completely replace the window.HubSpotConversations object when it
      // actually loads
      if (window.HubSpotConversations.widget.status().loaded) {
        clearInterval(interval);

        window.HubSpotConversations.widget.open();
      }
    }, 200);
  } else {
    window.HubSpotConversations.widget.open();
  }
}

const proxyFunction = () => {
  console.debug('Hubpost not initialized');
};

export const hubpostInitialState = {
  ready: false,
  tracker: {
    page: proxyFunction,
    identify: proxyFunction,
  },
  widget: null,
  openWidget: () => {
    console.warn("Attemped to open hubspot widget that wasn't loaded");
  },
};

function createTracker(_hsq = []) {
  const page = (location = window.location) => {
    const { pathname, search } = location;

    let path = pathname;
    if (search) {
      path += search;
    }

    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
  };

  const identify = ({ email, id }) => {
    _hsq.push(['identify', { email, id }]);
  };

  return { _hsq, page, identify };
}

export function loadHubspot() {
  if (config.ACTIVE_CRM !== 'hubspot' || !config.HUBSPOT_APP_ID) {
    return Promise.resolve({ ...hubpostInitialState });
  }

  // The fallback to {} here is just so script has some value when this
  // is rendered on the server
  let script = document.createElement('script') ?? {};

  script.src = `https://js.hs-scripts.com/${config.HUBSPOT_APP_ID}.js`;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  return new Promise((resolve) => {
    const handleOnLoad = () => {
      const _hsq = (window._hsq = window._hsq || []);

      resolve({
        ready: true,
        tracker: createTracker(_hsq),
        widget: window.HubSpotConversations,
        openWidget: open,
      });
    };

    script.onload = () => {
      // Hubspot chat seems to have this crap about the onload event
      // happening in one or the other of the cases below...
      if (window.HubSpotConversations) {
        handleOnLoad();
      } else {
        window.hsConversationsOnReady = [
          () => {
            handleOnLoad();
          },
        ];
      }
    };

    document.head.appendChild(script);
  });
}

function _reloadChatWidget() {
  // If it has already been initialized, refresh the widget so
  // generated token will be used
  if (
    !!window.HubSpotConversations &&
    window.HubSpotConversations.widget.status().loaded
  ) {
    window.HubSpotConversations.clear({ resetWidget: true });
  }
}

export function resetHubspotConfig(isMobile) {
  window.hsConversationsSettings = {
    loadImmediately: !isMobile,
  };

  _reloadChatWidget();
}

export function updateHubpostChatConfig({ isMobile, token, email }) {
  window.hsConversationsSettings = {
    ...window.hsConversationsSettings,
    ...(isMobile !== undefined && {
      loadImmediately: !isMobile,
    }),
    ...(!!token && {
      identificationEmail: email,
      identificationToken: token,
    }),
  };

  _reloadChatWidget();
}
