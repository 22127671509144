import styled, { css } from 'styled-components';

import { colors } from '@/styles/js/utils/theme';
import typeStyle from '@/styles/js/fonts/typeStyles';

import GeocodingSearchComp from '@/components/forms/Geocoding/Search';

export const GeocodingSearch = styled(GeocodingSearchComp)`
  width: 100%;
`;

export const ModalContent = styled.main`
  padding: 1rem;
  flex: 1;
`;

export const ModalFooter = styled.footer`
  padding: 1rem;
  width: 50%;
`;

export const inputCSS = css`
  border: none;
  border-radius: 4px;
  background: ${colors.faint};
`;

export const menuCSS = css`
  border: none;
  box-shadow: none;
`;

export const menuItemCSS = css`
  padding: 1.6rem 0.5rem;

  &:before {
    margin: 0 0.5rem;;
  }

  &:first-child:before {
    display: none;
  }
`;

export const Search = styled.button.attrs(() => ({
  type: 'button'
}))`
  ${typeStyle('button-s')}

  cursor: pointer;
  user-select: none;
  background: transparent;
  color: ${colors.textDisabled};
  border: solid 1px ${colors.rule};
  border-radius: 4px;
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  overflow-x: hidden;

  &:hover, &:active, &:focus {
    border-color: ${colors.primary};
  }

  & > * {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const Text = styled.span`
  white-space: nowrap;
  overflow-x: hidden;

  &:not(:first-child) {
    margin-left: 4px;
  }
`;
