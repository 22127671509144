import Icon from '@/components/graphics/Icon';
import Spacer from '@/components/layout/Spacer';

import useOutsideClick from '@/hooks/useOutsideClick';

import * as Styled from './index.styles';

const FilterMenu = ({
  open,
  onClickOutside,
  onClose,
  menu,
  children,
  wrapper,
  ...props
}) => {
  const ref = useOutsideClick(onClickOutside);

  let extraProps = {};

  if (wrapper) extraProps.as = wrapper;

  return (
    <Styled.Wrapper {...props} {...extraProps}>
      {children}

      {open && (
        <Styled.Menu ref={ref}>
          {onClose && (
            <>
              <Styled.CloseButton onClick={onClose}>
                <Icon type="close-x-24" />
              </Styled.CloseButton>
              <Spacer y={1} />
            </>
          )}
          {menu}
        </Styled.Menu>
      )}
    </Styled.Wrapper>
  );
};

export default FilterMenu;
