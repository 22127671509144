import { pathMatchRouteName } from '@/lib/routes';

function _somePathMatchRouteName(pathname = '', routes = []) {
  return routes.some((routeName) => pathMatchRouteName(pathname, routeName));
}

export function extractSlugsFromPathname(pathname = '') {
  const [spaceSlug, roomSlug] = pathname
    .replace(/(\/spaces\/|\/rooms\/)/, '')
    .split('/');

  return [spaceSlug, roomSlug];
}

export function isInSpaceDetailPage(pathname = '') {
  return _somePathMatchRouteName(pathname, [
    'spaceDetail',
    'spaceReserve',
    'spaceReserveConfirm',
  ]);
}

export function isInRoomDetailPage(pathname = '') {
  return _somePathMatchRouteName(pathname, [
    'roomDetail',
    'roomReserve',
    'roomReserveConfirm',
  ]);
}

export function isInRoomPage(pathname = '') {
  return _somePathMatchRouteName(pathname, [
    'roomIndex',
    'roomMap',
    'roomDetail',
    'roomList',
    'roomReserve',
    'roomReserveConfirm',
  ]);
}

export function isInSpacePage(pathname = '') {
  return _somePathMatchRouteName(pathname, [
    'index',
    'spaceIndex',
    'spaceMap',
    'spaceList',
    'spaceDetail',
    'spaceReserve',
    'spaceReserveConfirm',
  ]);
}
