import { usePathname } from 'next/navigation';
import React from 'react';

import { pathMatchRouteName } from '@/lib/routes';

const useRoutePathMatcher = (routeNames = [], exact = true) => {
  const pathname = usePathname();

  const routeNamesRef = React.useRef(routeNames);

  return React.useMemo(
    () =>
      !!routeNamesRef.current?.some?.((routeName) =>
        pathMatchRouteName(pathname, routeName, exact),
      ),
    [pathname, exact],
  );
};

export default useRoutePathMatcher;
