import styled, { css } from 'styled-components';
import { StyledNextLink } from '@/lib/next';

import mq from '@/styles/js/utils/mq';
import { colors } from '@/styles/js/utils/theme';
import { rgba } from '@/styles/js/utils/colors';

import FilterMenu from '../FilterMenu';
import { StyledCompanyFullImage, StyledCompanyLogo } from '@/components/brand/CompanyLogo';
import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';
import {
  SegmentedControl as StyledSegmentedControl,
  SegmentedControlOption as StyledSegmentedControlOption
} from '@/components/forms/SegmentedControl.styles';
import {
  ListOrMapToggle as StyledListOrMapToggle
} from '@/components/filters/ListOrMapToggle/index.styles';

const sideSpacing = '1rem';

export const FiltersNavbar = styled.section`
  border-bottom: solid 1px ${colors.rule};
  background: white;
  padding: 0;
  display: flex;
  flex-direction: column;

  ${mq('large')} {
    padding: 1.5rem 4rem;
    padding-right: 0;
  }

  box-shadow:
    -6px 0 transparent,
    6px 0 transparent,
    0px 3px 4px -2px ${rgba('black', 0.12)};
`;


export const FilterNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin: 0 ${sideSpacing};

  ${mq({ until: 'large' })} {
    padding: 1rem 0;

    ${({ asAppBar }) => asAppBar && css`
      margin: 0;
      padding: 1rem ${sideSpacing};

      box-shadow:
        -6px 0 transparent,
        6px 0 transparent,
        0px 3px 4px -2px ${rgba('black', 0.12)};
    `}

    ${({ asAppBar }) => !asAppBar && css`
      gap: 1rem;
    `}
  }
`;

export const MainNav = styled.nav`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-items: start;

  & > *:last-child {
    justify-self: end;
  }
  & > *:not(:first-child):not(:last-child) {
    place-self: center;
  }

  ${mq({ until: 'large' })} {
    gap: 1rem;

    ${StyledSegmentedControl} ${StyledSegmentedControlOption} {
      width: 100%;
      box-sizing: content-box;
    }
    ${StyledSegmentedControl} {
      width: -webkit-fill-available;
    }
  }
  ${mq({ until: 'medium' })} {
    grid-template-columns: auto 1.3fr 0.7fr;
    grid-template-rows: 1fr;

    ${StyledListOrMapToggle} {
      max-width: unset;
      flex-grow: 1;
      width: fill-available;
    }

    ${StyledSegmentedControl} ${StyledIcon} {
      // fix icon placement on ios
      display: inline-flex;
      place-items: center;
      width: -webkit-fill-available;
    }
    ${StyledSegmentedControl} svg {
      line-height: 0;
      height: 1.7rem;
      width: 1.7rem;
      margin-right: 0.5rem;
    }
  }
  ${mq({ until: 'small' })} {
    ${StyledSegmentedControl} {
      padding: 0;
      height: 2.5rem;
    }
  }
`;

export const SearchNav = styled.nav`
  width: 100%;
  display: flex;

  & > * {
    flex: 1;
  }
`;

export const MobileMainNav = styled.nav`
  display: flex;

`;

export const NavLogo = styled.div`
  overflow: hidden;

  &&& ${StyledCompanyLogo} {
    // fix icon placement on ios
    display: inline-flex;

    border-radius: 30%;
    width: 3.5rem;
    height: 3.5rem;

    > img,
    > svg {
      height: 100%;
    }
    > svg {
      width: 100%;
    }

    ${mq({ until: 'small' })} {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &&& ${StyledCompanyFullImage} {
    width: fill-available;
    border-radius: none;
    overflow: unset;
    > img {
      object-fit: contain;
      align-self: flex-start;
      justify-self: flex-start;
    }
  }
`;

export const LogoLink = styled(StyledNextLink)`
  display: inline-block;
`;

export const MenuButton = styled.button.attrs({
  type: 'button'
})`
  border: none;
  border-radius: 8%;
  background: none;

  &&& svg {
    margin: auto;
    fill: ${colors.ruleDark};
  }
`;

export const DesktopFilterMenu = styled(FilterMenu)`
  display: flex;
  gap: 1rem;
`;
