import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  max-width: 39rem;

  & > *:last-child {
    margin: -2rem auto 0;
  }
`;

const breatherGreen = '#09A75E';

export const BreatherSection = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border: 2px solid ${breatherGreen};
  border-radius: 8px;
`;

export const BreatherLogoWrapper = styled.div`
  position: relative;
  background-color: ${breatherGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
`;

export const BreatherLogo = styled.img`
  position: absolute;
  height: 4rem;
  width: auto;
`;

export const BreatherContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
