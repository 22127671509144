import FiltersNavbar from '@/components/filters/FiltersNavbar';
import Mq from '@/components/helpers/Mq';
import NavLarge from '@/components/nav/NavLarge';

import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';

import * as S from './index.styles';

function Menu() {
  const showSimpleNav = useRoutePathMatcher(['guestInvitationIndex']);
  const filterShadow = useRoutePathMatcher(['roomMap', 'spaceMap']);
  const shouldShowFilters = useRoutePathMatcher([
    'roomMap',
    'spaceMap',
    'spaceList',
    'roomList',
  ]);

  return (
    <S.Menu>
      <Mq min="large">
        <NavLarge simple={showSimpleNav} />
      </Mq>

      {shouldShowFilters && <FiltersNavbar shadow={filterShadow} />}
    </S.Menu>
  );
}

export default Menu;
