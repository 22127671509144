import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { childrenType } from '@/lib/props';

import * as Styled from './index.styles';

export const animatedNotificationClassName = 'AnimatedNotificationClassName';

/*
 * TODO we only use react-transtion-group here. We may want to remove it eventually
 */
const NotificationTransitionGroup = ({
  persistent = false,
  notificationsByKey = [],
  timeout = 400,
  children = () => null,
}) => {
  const nodeRef = useRef(null);
  return (
    <Styled.NotificationTransitionGroup $persistent={persistent}>
      {notificationsByKey.map(([key, data]) => {
        const notification = children(data, key);

        if (!notification) {
          return null;
        }

        return (
          <CSSTransition
            nodeRef={nodeRef}
            key={key}
            timeout={timeout}
            classNames={animatedNotificationClassName}
          >
            {notification}
          </CSSTransition>
        );
      })}
    </Styled.NotificationTransitionGroup>
  );
};

NotificationTransitionGroup.propTypes = {
  persistent: PropTypes.bool,
  notificationsByKey: PropTypes.array,
  timeout: PropTypes.number,
  children: childrenType,
};

export default NotificationTransitionGroup;
