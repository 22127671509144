import { useRouter } from 'next/navigation';
import { useContext } from 'react';

import Notification from '@/components/notifications/Notification';

import UserContext from '@/context/User';

import route from '@/lib/routes';

const AccountOnHoldNotification = ({ closeNotification }) => {
  const router = useRouter();
  const { authenticated, user } = useContext(UserContext);

  if (authenticated && user.billingHold) {
    return (
      <Notification
        warning
        onIconClick={closeNotification}
        onClick={() => router.push(route('accountShow'))}
      >
        Psst, your Account is On Hold because we're having trouble with your
        billing info. Please update your payment details.
      </Notification>
    );
  } else if (authenticated && user?.teamOrganization?.billingHold) {
    return (
      <Notification
        warning
        onIconClick={closeNotification}
        onClick={() => router.push(route('accountShow'))}
      >
        Your team's account is currently on hold because we're having trouble
        with your team's billing info. Please notify your team manager.
      </Notification>
    );
  }

  return null;
};

export default AccountOnHoldNotification;
