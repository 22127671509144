import { usePathname, useRouter } from 'next/navigation';
import { useContext } from 'react';

import Mq from '@/components/helpers/Mq';
import Notification from '@/components/notifications/Notification';

import UserContext from '@/context/User';

import route, { pathMatchRouteName } from '@/lib/routes';

const SignupCTANotification = () => {
  const { authenticated } = useContext(UserContext);
  const router = useRouter();
  const pathname = usePathname();

  const onBannerClick = () => router.push(route('signup'));

  const notInRoutes = ![
    'guestInvitationIndex',
    'teamSignup',
    'teamSignupProfile',
    'signup',
  ].some((it) => pathMatchRouteName(pathname, it));

  const shouldShowBanner = [!authenticated, notInRoutes].every(Boolean);

  return (
    <Mq min="large">
      {shouldShowBanner && (
        <Notification icon="arrow" onClick={onBannerClick}>
          Get instant desks, meeting rooms, and private offices around the
          globe. Simply select and go!
        </Notification>
      )}
    </Mq>
  );
};

export default SignupCTANotification;
