import PropTypes from 'prop-types';
import { memo, useCallback, useRef } from 'react';

import * as S from './SegmentedControl.styles';

/**
 * Allows the selection of one from 2 or more options.
 * If an **onClick** function prop is passed, it will be called
 * on each click, passing (value) as params.
 */
const SegmentedControl = memo(
  ({ onClick = () => {}, name, options, value, ...props }) => {
    const optionsRef = useRef(options);

    const indexForValue = useCallback(
      () => optionsRef.current.findIndex((it) => it.value === value),
      [value],
    );

    return (
      <S.SegmentedControl {...props}>
        {optionsRef.current.map((option, idx) => {
          const identifier = [name, String(idx)].join('_');
          const checked = value === option.value;

          return (
            <S.SegmentedControlOption
              key={option.value}
              checked={checked}
              onClick={() => onClick(option.value)}
            >
              {idx === 0 && (
                <S.SegmentedControlBg
                  style={{
                    transform: `translateX(${100 * indexForValue()}%)`,
                  }}
                />
              )}

              <input
                readOnly
                type="radio"
                name={name}
                value={option.value}
                id={identifier}
                checked={checked}
              />
              <label htmlFor={identifier}>{option.label}</label>
            </S.SegmentedControlOption>
          );
        })}
      </S.SegmentedControl>
    );
  },
);

SegmentedControl.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'mini']),
};

export default SegmentedControl;
