import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { childrenType } from '@/lib/props';

import * as S from './BadgeList.styles';

/**
 * Wraps a list of <Badge> components in order to
 * display them with appropriate spacing.
 */
const BadgeList = forwardRef(({ children, onClick = () => {} }, ref) => {
  return (
    <S.BadgeList onClick={onClick} ref={ref}>
      {children}
    </S.BadgeList>
  );
});

BadgeList.propTypes = {
  children: childrenType,
  onClick: PropTypes.func,
};

export default BadgeList;
