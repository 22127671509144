/**
 * Get an object describing all amenities
 *
 * @return   {Object}  All amenities
 */
export const getAmenities = () => {
  return {
    espresso: {
      icon: 'coffee-24',
      label: 'Espresso',
    },
    'shared-printer': {
      icon: 'printer-24',
      label: 'Shared Printer',
    },
    'full-kitchen': {
      icon: 'kitchen-24',
      label: 'Full Kitchen',
    },
    wifi: {
      label: 'High-Speed Wifi',
      icon: 'wifi-24',
    },
    'phone-line-hookup': {
      label: 'Phone Line Hookup',
      icon: 'phone-24',
    },
    'free-parking': {
      label: 'Free Parking',
      icon: 'car-24',
    },
  };
};

export function groupAmenitiesByType(amenities, roomAmenities) {
  let moods = [];
  let transit = [];
  let other = [];
  let allAmenitiesBySlug = {};
  let allRoomAmenitiesBySlug = {};

  for (let amenity of roomAmenities) {
    // Add to list of all amenities
    allRoomAmenitiesBySlug[amenity.slug] = amenity;
  }

  for (let amenity of amenities) {
    // Add to list of all amenities
    allAmenitiesBySlug[amenity.slug] = amenity;

    // Then sort by mood or other
    if (amenity.type === 'mood') {
      moods.push(amenity);
    } else if (amenity.type === 'transit') {
      transit.push(amenity);
    } else {
      other.push(amenity);
    }
  }

  return {
    other,
    moods,
    transit,
    allAmenitiesBySlug,
    allRoomAmenitiesBySlug,
  };
}
