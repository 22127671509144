import styled, { css } from 'styled-components';

import mq from '@/styles/js/utils/mq';
import units from '@/styles/js/units';
import { scaleWithVW, scaleWithVH } from '@/styles/js/utils/scaling';
import { commonSpacing } from '@/styles/js/utils/spacing';
import { rgba } from '@/styles/js/utils/colors';

import { NavRow } from '@/components/nav/NavRow/index.styles';
import { StyledLayoutHourlyTimeSelection } from '@/components/layout/LayoutHourlyTimeSelection';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 62rem;

  @supports (-webkit-touch-callout: none) {
    /* Ensure bottom content is visible above sticky footer on iOS-17 devices */
    padding-bottom: calc(2rem + env(safe-area-inset-bottom));
  }
`;

export const Header = styled.header`
  z-index: ${units.zIndex.navBar};
  flex-shrink: 0;
  position: relative;
  background-color: white;

  // Rule
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: ${scaleWithVW('2.5rem')};
    right: ${scaleWithVW('2.5rem')};
    border-bottom: 1px solid ${({ theme }) => theme.colors.rule};
    height: 1px;
    bottom: 0;
    transition: opacity 0.2s;
    z-index: 1;
    will-change: opacity;
    opacity: ${(props) => (props.rule ? 0.99 : 0)};

    ${mq('medium')} {
      opacity: 0;
      left: 40px;
      right: 40px;
      z-index: 1;
    }
  }

  // Shadow
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 0.9rem;
    background-image: linear-gradient(
      180deg,
      ${rgba('black', 0.04)},
      transparent
    );
    left: 0;
    right: 0;
    bottom: -0.9rem;
    width: 100%;
    transition: opacity 0.2s;
    will-change: opacity;
    opacity: ${(props) => props.rule || props.isInitialScrollPosition ? 0 : 1};
  }

  ${mq({ until: 'medium' })} {
    top: 0;
    position: sticky;
  }

  ${(props) => props.sticky && css`
    top: 0;
    position: sticky;
  `}
`;

export const Content = styled.div`
  ${commonSpacing}

  ${(props) => !props.noPadding && css`
    padding-left: ${scaleWithVW('2.5rem')};
    padding-right: ${scaleWithVW('2.5rem')};
  `}

  > ${NavRow} {
    margin-left: ${scaleWithVW('2.5rem', { negative: true })};
    margin-right: ${scaleWithVW('2.5rem', { negative: true })};
  }

  > .AlertModalContent,
  > ${StyledLayoutHourlyTimeSelection} {
    flex: 1 0 auto;
    margin-top: 0;
  }

  ${(props) => props.flexContent && css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  `}

  ${(props) => props.flexCenter && css`
    align-items: center;
    justify-content: center;
  `}

  ${(props) => props.smallPadBottom && css`
    padding-bottom: ${scaleWithVH('2rem')};
  `}

  ${(props) => props.noPadBottom && css`
    padding-bottom: 0;
  `}

  ${(props) => !!props.rule && css`
    ${mq({ until: 'medium' })} {
      padding-top: ${scaleWithVH('2rem')};
    }
  `}
`;

export const Footer = styled.footer`
  flex-shrink: 0;

  ${({ $bottomFooter }) => $bottomFooter && css`
    margin-top: auto;
  `}

  ${({ $stickyBottomFooter }) => $stickyBottomFooter && css`
    z-index: 1;
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
    position: sticky;
    background-color: white;

    @supports (-webkit-touch-callout: none) {
      /* Ensure footer content is visible on iOS-17 devices */
      padding-bottom: calc(2rem + env(safe-area-inset-bottom));
    }
  `}

  ${({ $footerContent }) => $footerContent && css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    gap: ${scaleWithVH('.5rem')};
    padding: ${scaleWithVH('.5rem')} ${scaleWithVW('2.5rem')};
    border-top: 1px solid ${({ theme }) => theme.colors.rule};
  `}
`;

export const ModalContentNavClose = styled.button.attrs({
  type: 'button',
})`
  height: 2.4rem;
  width: 2.4rem;
  display: block;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
`;
