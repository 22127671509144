import { componentWithName } from 'airbnb-prop-types';
import PropTypes from 'prop-types';
import React, {
  cloneElement,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import useWindowResize from '@/hooks/useWindowResize';

import * as Styled from './Tabs.styles';

const Tabs = ({
  children,
  selected,
  full = false,
  bordered = false,
  onChange = () => null,
}) => {
  const currentWindowSize = useWindowResize();

  // Use to keep mutable state
  const selfRef = useRef({ ready: false });
  const activeTabRef = useRef({ ready: false });

  // Inline styles that will create the transition effect on the active bar
  const [activeBarStyle, setActiveBarStyle] = useState({
    width: '0px',
  });

  // Callback that updates the active bar position whenever tabs change
  const updateActiveBarStyle = useCallback(() => {
    if (activeTabRef.current.tabEl) {
      const activeTabOffsetWidth = activeTabRef.current.tabEl.offsetWidth;
      const activeTabIndex =
        activeTabRef.current.tabEl.getAttribute('tabIndex');
      const tabDomElList = Array.from(selfRef.current.tabListEl.childNodes);

      const offset = tabDomElList
        .slice(0, activeTabIndex)
        .reduce((total, option) => total + option.offsetWidth, 0);

      setActiveBarStyle({
        width: activeTabOffsetWidth || '0px',
        transform: `translateX(${offset}px)`,
      });
    }
  }, []);

  // Callback ref to initialize the tabList element
  const getTabListRef = useCallback((tabListEl) => {
    selfRef.current.ready = true;
    selfRef.current.tabListEl = tabListEl;
  }, []);
  const getActiveTabRef = useCallback((tabEl) => {
    activeTabRef.current.ready = true;
    activeTabRef.current.tabEl = tabEl;
  }, []);

  // Handle change tabs and page resizes
  useLayoutEffect(() => {
    if (selfRef.current.ready && activeTabRef.current.ready) {
      updateActiveBarStyle();
    }
  }, [
    selected,
    updateActiveBarStyle,
    // Update if window size changes
    currentWindowSize.width,
    currentWindowSize.height,
  ]);

  // Maps the child components enriching them with new props for the active state and change event
  const childrenMapper = (child, tabIndex) =>
    cloneElement(child, {
      ...child.props,
      tabIndex,
      active: child.props.name === selected,
      activeTabRef: child.props.name === selected ? getActiveTabRef : () => {},
      onClick: () => onChange(child.props.name),
    });

  return (
    <Styled.Tabs $full={full} $bordered={bordered}>
      <Styled.TabsList ref={getTabListRef}>
        {React.Children.map(children, childrenMapper)}
      </Styled.TabsList>

      <Styled.TabsActiveBar style={activeBarStyle} />
    </Styled.Tabs>
  );
};

const Tab = ({ active, activeTabRef, ...remainingProps }) => {
  return <Styled.Tab {...remainingProps} ref={activeTabRef} $active={active} />;
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    componentWithName('Tab'),
    PropTypes.arrayOf(componentWithName('Tab')),
  ]).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func,
  full: PropTypes.bool,
  bordered: PropTypes.bool,
};

export { Tab };
export default memo(Tabs);
