import { useRouter } from 'next/navigation';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import { useFilterContext } from '@/context/Filter';
import { useGeolocationContext } from '@/context/Geolocation';
import { useUserContext } from '@/context/User';

import useEvent from '@/hooks/useEvent';

import route from '@/lib/routes';

const useGeocodingFilter = () => {
  const router = useRouter();

  const { coords, available } = useGeolocationContext();
  const { authenticated, user } = useUserContext();
  const {
    updateFilters,
    filters: { place },
  } = useFilterContext();

  const [, getPlace] = useDeskpassAPI((api) => api.geocoding.getPlace, {
    fireOnMount: false,
  });

  const onSelect = useEvent(async (selectedItem) => {
    const { deskpassType, googlePlaceId, slug } = selectedItem;

    if (deskpassType === 'space') {
      updateFilters({ place: selectedItem });

      return router.push(route('spaceDetail', { spaceSlug: slug }));
    }

    const place = await getPlace(googlePlaceId);
    updateFilters({ place });
  });

  let geocodingParams = {};

  if (available) {
    geocodingParams.proximity = coords;
  } else if (authenticated && !!user.city) {
    const { lat, lng } = user.city;
    geocodingParams.proximity = { lat, lng };
  }

  return {
    place,
    onSelect,
    geocodingParams,
  };
};

export default useGeocodingFilter;
