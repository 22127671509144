import Drawer from '@/components/bits/Drawer';
import MoreView from '@/components/views/MoreView';

import { useMoreModalContext } from '@/context/MoreModal';

function MoreModal() {
  const { openMore, toggleMoreModal } = useMoreModalContext();

  return (
    <Drawer open={openMore} onClose={toggleMoreModal}>
      <MoreView />
    </Drawer>
  );
}

export default MoreModal;
