import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

import SegmentedControl from '@/components/forms/SegmentedControl';

import useEvent from '@/hooks/useEvent';
import useMediaQuery from '@/hooks/useMediaQuery';
import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';

import route from '@/lib/routes';

import * as S from './index.styles';

/**
 * ListOrMapToggle
 * ---
 * Used to control search result display as a list or in map.
 */
const ListOrMapToggle = ({ small, mini }) => {
  const verySmallScreens = useMediaQuery({ max: 'small' });
  const inSpaceListRoute = useRoutePathMatcher(['spaceList']);
  const inMapRoutes = useRoutePathMatcher(['spaceMap', 'roomMap']);
  const router = useRouter();

  let value = inMapRoutes ? 'map' : 'list';

  const showIcon = !verySmallScreens;

  const options = useMemo(
    () => [
      {
        value: 'list',
        label: (
          <S.LabelContent>
            {showIcon && <S.ListIcon />} <span>List</span>
          </S.LabelContent>
        ),
      },
      {
        value: 'map',
        label: (
          <S.LabelContent>
            {showIcon && <S.MapIcon />} <span>Map</span>
          </S.LabelContent>
        ),
      },
    ],
    [showIcon],
  );

  const onClick = useEvent((value) => {
    let nextRoute = inSpaceListRoute ? 'spaceMap' : 'roomMap';

    if (value === 'list') {
      nextRoute = inSpaceListRoute ? 'spaceList' : 'roomList';
    }

    router.replace(route(nextRoute));
  });

  const segmentControlSize = small ? 'small' : mini ? 'mini' : undefined;

  return (
    <S.ListOrMapToggle>
      <SegmentedControl
        name="search-result-mode-switch"
        value={value}
        size={segmentControlSize}
        options={options}
        onClick={onClick}
      />
    </S.ListOrMapToggle>
  );
};

export default ListOrMapToggle;
