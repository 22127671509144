import styled, { css } from 'styled-components';

import { colors } from '@/styles/js/utils/theme';
import { rgba } from '@/styles/js/utils/colors';

export const TabsList = styled.div`
  display: inline-flex;
  padding: 1.4rem 0;
`;

export const TabsActiveBar = styled.div`
  height: 0.5rem;
  position: absolute;
  background: ${colors.primary};
  transition-duration: 160ms;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-property: transform, width;
`;

export const Tab = styled.div`
  margin-left: 3rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${rgba(colors.text, 0.7)};
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  ${({ $active }) => $active && css`
    color: ${colors.text};
  `}
`;

export const Tabs = styled.div`
  ${({ $full }) => $full && css`
    ${TabsList} {
      width: 100%;
      display: flex;
    }

    ${Tab} {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
    }
  `}

  ${({ $bordered }) => $bordered && css`
    ${TabsList} {
      border-bottom: solid 0.5rem ${colors.rule};
    }

    ${TabsActiveBar} {
      margin-top: -0.5rem;
    }
  `}
`;