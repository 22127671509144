import styled, { keyframes, css } from 'styled-components';

import mq from '@/styles/js/utils/mq';
import { rgba } from '@/styles/js/utils/colors';
import typeStyle from '@/styles/js/fonts/typeStyles';

import { animatedNotificationClassName } from '@/components/notifications/NotificationTransitionGroup';

const opacityTransition = css`
  transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: opacity;
`;

export const Content = styled.div`
  ${opacityTransition}
  font-weight: bold;

  a.underline {
    text-decoration: underline;
  }
`;

export const CloseIcon = styled.div`
  ${opacityTransition}

  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 4rem;
  padding-top: env(safe-area-inset-top);
  bottom: calc(50% - 2rem);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq({ until: 'medium' })} {
    right: 1rem;
  }
`;

/*
 * TODO We may refactor this animations at some point if we remove react-transition-group lib
 */
export const Notification = styled.div`
  ${typeStyle('body')}

  padding: 1rem 5rem 1.2rem;
  margin-top: calc(env(safe-area-inset-top) * -1);
  padding-top: calc(env(safe-area-inset-top) + 1rem);
  text-align: center;
  position: relative;
  user-select: none;

  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
  transform-origin: 0 0;

  // ---------------------------------------------------------------
  // Animation State
  // ---------------------------------------------------------------

  &.${animatedNotificationClassName}-enter,
    &.${animatedNotificationClassName}-exit,
    &.${animatedNotificationClassName}-exit-done {
    transform: scaleY(0);

    ${Content},
    ${CloseIcon} {
      opacity: 0;
    }
  }

  &.${animatedNotificationClassName}-exit,
    &.${animatedNotificationClassName}-exit-done {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &.${animatedNotificationClassName}-enter-active,
    &.${animatedNotificationClassName}-enter-done {
    transform: scaleY(1);

    ${Content},
    ${CloseIcon} {
      opacity: 1;
      transition-delay: 0.15s;
    }
  }

  & + & {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => rgba(theme.colors.black, 0.15)};
    }
  }

  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.text};
  `}

  ${({ theme, special }) => special && css`
    background-color: ${theme.colors.primary};
  `}

  ${({ theme, warning }) => warning && css`
    background-color: ${theme.colors.red};
  `}

  ${mq('medium')} {
    padding: 2.7rem 8.5rem 3.1rem;
  }

  ${(props) => props.clickable && css`
    cursor: pointer;
  `}
`;

const scaleUp = keyframes`
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
`;

const linearProgressAnimation = css`
  animation-name: ${scaleUp};
  animation-duration: ${({ timeout }) => timeout / 1000 + 's'};
  animation-timing-function: linear;
  animation-iteration-count: 1;
  transform-origin: 100% 0;
  will-change: transform;
`;

export const ProgressIndicator = styled.span`
  ${linearProgressAnimation}

  display: block;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.black, 0.5)};

  ${({ theme, special }) => special && css`
    background-color: ${rgba(theme.colors.black, 0.1)};
  `}

  ${({ theme, warning }) => warning && css`
    background-color: ${rgba(theme.colors.black, 0.15)};
  `}
`;
