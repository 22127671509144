import styled, { css } from 'styled-components';

import typeStyle from '@/styles/js/fonts/typeStyles';
import fluidSize from '@/styles/js/utils/fluidSize';
import mq from '@/styles/js/utils/mq';
import { notTouch } from '@/styles/js/utils/media';
import { notSelectable } from '@/styles/js/utils/interations';
import { absolute as positionAbsolute } from '@/styles/js/utils/position';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';

import crosshatchInactive from '@svgs/full/crosshatch-inactive.svg?url';
import crosshatchActive from '@svgs/full/crosshatch-active.svg?url';
import crosshatchWarning from '@svgs/full/crosshatch-warning.svg?url';

/*
TimeSpan

Interaction for selecting a block of time.

.is-unavailable          - Added if the entire day is unavailable
*/

const timeSpanLabelGutterWidth = '5.5rem';

const sharedStyles = css`
  top: 0;
  position: absolute;
  left: ${timeSpanLabelGutterWidth};
  ${fluidSize('outer-l', 'right')};

  ${mq('medium')} {
   ${fluidSize('outer-m', 'right')};
  }
`;

export const TimeSpanDuration = styled.div`
  ${sharedStyles}

  pointer-events: none;
`;

export const TimeSpanDurationText = styled.span`
  ${typeStyle('micro')};
  ${positionAbsolute};

  margin: auto;
  color: white;
  text-align: center;
  width: 9rem;
  height: 1.8rem;
`;

export const TimeSpanHandle = styled.div`
  position: absolute;
  z-index: 1;
  width: 9.6rem;

  &:nth-child(5) {
    left: 2rem;
    margin-left: ${timeSpanLabelGutterWidth};
  }

  &:nth-child(6) {
    right: 2rem;
    ${fluidSize('outer-m', 'margin-right')};
  }

  ${({ $hidden }) => $hidden && css`
    display: none;
  `}
`;

export const TimeSpanHandleTime = styled.span`
  ${typeStyle('micro')};
  ${positionAbsolute};

  margin: auto;
  display: block;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.text};
  line-height: 4.2rem;
  height: 4.2rem;
  border-radius: 2.1rem;
  position: absolute;
  cursor: ns-resize;
  padding-left: 1.1rem;

  ${notTouch} {
    &:hover {
      background-color: black;
    }
  }

  &:active {
    background-color: black;
  }
`;

export const TimeSpanHandleIcon = styled.span`
  color: white;
  position: absolute;
  top: 1.3rem;
  left: 0.7rem;
`;

export const TimeSpanSelected = styled.div`
  ${sharedStyles}
`;

export const TimeSpanSelectedBg = styled.div`
  ${positionAbsolute}

  background-color: ${({ theme }) => theme.colors.primary};
`;

export const TimeSpanSlot = styled.div.attrs({
    className: 'TimeSpan__slot',
  })`
  ${fluidSize('outer-l', 'right')};

  position: absolute;
  left: ${timeSpanLabelGutterWidth};
  background-size: 2.4rem 2.4rem;

  ${mq('medium')} {
    ${fluidSize('outer-m', 'right')};
  }

  ${({ $disabled }) => $disabled && css`
    background-image: url('${crosshatchInactive.src}');
  `}

  ${({ $preselected }) => $preselected && css`
    background-image: url('${crosshatchActive.src}');
  `}

  &.is-overlap {
    background-image: url('${crosshatchWarning.src}');
  }
`;

export const TimeSpanRow = styled.div`
  position: relative;

  ${({ $height }) => $height && css`
    height: ${$height}px;
  `}

  &::before {
    left: ${timeSpanLabelGutterWidth};
    ${pseudoAbsolute};

    height: 1px;
    background-color: ${({ theme }) => theme.colors.rule};
    top: 0;
    right: 0;
    width: 100%;
  }

  &::after {
    left: ${timeSpanLabelGutterWidth};
    ${pseudoAbsolute};

    height: 1px;
    background-color: ${({ theme }) => theme.colors.rule};
    top: 50%;
    right: 0;
    width: 10%;
  }
`;

export const TimeSpanRowHour = styled.span`
  width: ${timeSpanLabelGutterWidth};
  ${typeStyle('micro')};

  color: ${({ theme }) => theme.colors.text};
  text-align: right;
  position: absolute;
  padding-right: 1.2rem;
  display: block;
  left: 0;
  top: -0.8rem;
`;

export const TimeSpan = styled.div.attrs({
  className: 'TimeSpan',
})`
  ${notSelectable}

  overflow: hidden;
  overflow-y: auto;

  ${({ $isUnavailable }) => $isUnavailable && css`
    ${TimeSpanDuration},
    ${TimeSpanHandle},
    ${TimeSpanSelected} {
      display: none;
    }

    ${TimeSpanSlot} {
      background-image: url('${crosshatchInactive.src}');
    }
  `}
  }
`;

export const TimeSpanInner = styled.div`
  // This only needs to be set to prevent the parent .TimeSpan
  // from growing too big when using flexbox;
  max-height: 50px;
`;
