import Icon from '@/components/graphics/Icon';

import useEvent from '@/hooks/useEvent';

import DeskWhenFilter from './DeskWhenFilter';
import MeetWhenFilter from './MeetWhenFilter';
import OfficeWhenFilter from './OfficeWhenFilter';
import * as Styled from './index.styles';
import useWhenFilterProps from './useWhenFilterProps';

const WhenFilterOptions = ({ onClose }) => {
  const { reset, filters } = useWhenFilterProps();

  const { start, end, date, bookingType, isOffice } = filters;

  const onResetClick = useEvent((e) => {
    e.preventDefault();
    e.stopPropagation();

    reset();
  });

  return (
    <Styled.WhenFilterOptions>
      <Styled.ClearDateLink
        mini
        icon="close-x-17"
        onClick={onResetClick}
        visible={start || end || date}
      >
        Clear Date
      </Styled.ClearDateLink>

      {onClose && (
        <Styled.CloseButton onClick={onClose}>
          <Icon type="close-x-24" />
        </Styled.CloseButton>
      )}

      {bookingType === 'deskpass' && <DeskWhenFilter />}

      {bookingType === 'hourly' && !isOffice && <MeetWhenFilter />}

      {bookingType === 'hourly' && isOffice && <OfficeWhenFilter />}
    </Styled.WhenFilterOptions>
  );
};

export default WhenFilterOptions;
