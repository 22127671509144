import React, { createContext, useCallback, useState } from 'react';

import api from '@/api/deskpass';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';

import { groupAmenitiesByType } from '@/lib/amenities';
import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});

export const initialState = {
  ready: false,
  amenities: [],
  moods: [],
  transit: [],
  roomAmenities: [],
  allAmenitiesBySlug: {},
  allRoomAmenitiesBySlug: {},
};

export function AmenityProvider({ children, serverData }) {
  const [state, setState] = useState(() => ({
    ...initialState,
    ...(serverData.amenity && { ready: true, ...serverData.amenity }),
  }));

  const slugToName = useCallback(
    (slug) => state.allAmenitiesBySlug[slug]?.name,
    [state.allAmenitiesBySlug],
  );

  const roomSlugToName = useCallback(
    (slug) => state.allRoomAmenitiesBySlug[slug]?.name,
    [state.allRoomAmenitiesBySlug],
  );

  const loadAmenities = useEvent(async () => {
    try {
      const [amenities, roomAmenities] = await Promise.all([
        api.space.amenities.getAll(),
        api.room.amenities.getAll(),
      ]);

      const {
        other,
        moods,
        transit,
        allAmenitiesBySlug,
        allRoomAmenitiesBySlug,
      } = groupAmenitiesByType(amenities, roomAmenities);

      const newState = {
        allRoomAmenitiesBySlug,
        allAmenitiesBySlug,
        roomAmenities,
        moods,
        transit,
        amenities: other,
      };

      setState((prevState) => ({
        ...prevState,
        ...newState,
        ready: true,
      }));
    } catch (error) {
      console.error('Failed to load amenities:', error);
    }
  });

  useMount(() => {
    if (!state.ready) {
      loadAmenities();
    }
  });

  const contextValue = {
    ...state,
    slugToName,
    roomSlugToName,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useAmenityContext = () => React.useContext(Context);
export const withAmenityContext = consumerToHOC(
  Context.Consumer,
  'amenityContext',
);
export default Context;
