import { usePathname } from 'next/navigation';

import useWatch from './useWatch';

const useOnRouteChange = (onChange) => {
  const pathname = usePathname();

  useWatch(() => {
    onChange(pathname);
  }, [pathname]);

  return pathname;
};

export default useOnRouteChange;
