import styled, { css } from 'styled-components';

import TextLink from '@/components/buttons-links/TextLink';
import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';
import mq from '@/styles/js/utils/mq';

export const WhenFilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`;

export const ClearDateLink = styled(TextLink)`
  align-self: flex-start;

  ${({ visible }) => !visible && css`
    visibility: hidden;
  `}
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 1.5rem;
  right: .5rem;
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
  outline: inherit;
  ${StyledIcon} * {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    place-content: center;
  }
  ${mq('smallMedium')} {
    top: 2.5rem;
    right: 1.2rem;
  }
`;
