'use client';

import { useSetIsIncomingBreatherUser } from '@/components/breather/hooks';

import useOnRouteChange from '@/hooks/useOnRouteChange';

import { page } from '@/lib/analytics';

import BottomContentLayout from './BottomContent';
import TopContentLayout from './TopContent';
import * as Styled from './layout.styles';

/*
 * Entry point that makes Layout "client side only".
 */
export function ClientStaticRootLayout({ children }) {
  // Breather Incoming Session
  useSetIsIncomingBreatherUser();

  useOnRouteChange(() => {
    if (typeof window === 'undefined') return;

    page(location);
  });

  return (
    <Styled.AppLayout>
      <TopContentLayout />
      {children}
      <BottomContentLayout />
    </Styled.AppLayout>
  );
}
