import { useRouter } from 'next/navigation';
import { memo, useMemo } from 'react';

import Notification from '@/components/notifications/Notification';
import NotificationTransitionGroup from '@/components/notifications/NotificationTransitionGroup';

import { useNotificationContext } from '@/context/Notification';

/**
 * Render temporary notifications
 */
const TemporaryNotificationList = memo(() => {
  const { notifications, destroy: destroyNotification } =
    useNotificationContext();
  const router = useRouter();

  const notificationsByKey = useMemo(() => {
    return notifications.reduce((acc, it) => {
      const { id, ...notificationData } = it;
      return [...acc, [id, notificationData]];
    }, []);
  }, [notifications]);

  return (
    <NotificationTransitionGroup notificationsByKey={notificationsByKey}>
      {(notification, id) => {
        return (
          <Notification
            special={notification.style === 'special'}
            warning={notification.style === 'warning'}
            timeout={notification.timeout}
            icon={notification.url ? 'arrow' : 'close'}
            onClick={() => {
              if (notification.url) {
                router.push(notification.url);
              }

              destroyNotification(id);
            }}
          >
            {notification.message}
          </Notification>
        );
      }}
    </NotificationTransitionGroup>
  );
});

export default TemporaryNotificationList;
