import Mq from '@/components/helpers/Mq';
import FewerEmailsModal from '@/components/modals/FewerEmailsModal';
import LoginSignupModal from '@/components/modals/LoginSignupModal';
import MoreModal from '@/components/modals/MoreModal';
import NavSmall from '@/components/nav/NavSmall';

import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';

export default function BottomContentLayout() {
  const showSimpleNav = useRoutePathMatcher(['guestInvitationIndex']);

  return (
    <>
      <Mq max="large">
        <NavSmall simple={showSimpleNav} />
        <MoreModal />
      </Mq>

      <LoginSignupModal />
      <FewerEmailsModal />
    </>
  );
}
