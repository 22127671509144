import styled, { css } from 'styled-components';
import { StyledNextLink } from '@/lib/next';

import mq from '@/styles/js/utils/mq';
import units from '@/styles/js/units';
import { rgba } from '@/styles/js/utils/colors';
import { scaleWithVW } from '@/styles/js/utils/scaling';
import { colors } from '@/styles/js/utils/theme';
import typeStyle from '@/styles/js/fonts/typeStyles';

const navItemCss = css`
  ${typeStyle('mini')}

  color: ${colors.textLight};
  font-weight: bold;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:active {
    color: ${colors.accent};
  }

  ${mq({ until: 'small' })} {
    padding-top: 0.4em;
    padding-bottom: 0.6em;
  }
`;

export const NavItemAnchor = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${navItemCss}
`;

export const NavItemButton = styled.button.attrs({
  type: 'button'
})`
  ${navItemCss}
`;

export const NotificationDot = styled.span`
  position: absolute;
  top: 1rem;
  right: -1.4rem;

  min-width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;

  background-color: ${colors.primaryDark};
  color: white;

  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.4rem;
  text-align: center;
`;

export const NavItem = styled(StyledNextLink).attrs({
  activeClassName: 'is-active'
})`
  ${navItemCss}

  &.is-active {
    color: ${colors.text};
  }
`;

export const NavSmall = styled.nav`
  padding: 0 ${scaleWithVW('2rem')};
  background-color: white;
  height: calc(${units.components.navSmallHeight} + env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;

  display: flex;
  justify-content: space-between;

  &:before {
    content: ' ';
    position: absolute;
    right: ${scaleWithVW('2rem')};
    left: ${scaleWithVW('2rem')};
    top: -1px;
    height: 1px;
    border-top: 1px solid ${colors.rule};
  }

  ${mq({ until: 'small' })} {
    height: calc(${units.components.navSmallerHeight} + env(safe-area-inset-bottom));
  }

  ${mq({ until: 'large' })} {
    width: 100vw;
  }

  ${({ $reverse }) => $reverse && css`
    background-color: transparent;

    &:before {
      border-top-color: ${rgba('white', 0.2)};
    }

    ${NavItem},
    ${NavItemButton},
    ${NavItemAnchor} {
      color: ${rgba('white', 0.4)};
    }

    ${NavItem} {
      &.is-active {
        color: ${rgba('white', 1)};
      }
    }
  `}
`;

export const NavSmallSpacer = styled.div`
  display: none;
  visibility: hidden;
  ${mq({ until: 'large' })} {
    display: block;
    visibility: visible;
    margin-top: 2rem;
    height: calc(${units.components.navSmallerHeight} + env(safe-area-inset-bottom));
  }
`;
